:root {
  --unit: 10px;
  --font-family-inter: 'Inter', trebuchet ms, verdana, 'Arial', sans-serif;
}

body {
  color: var(--color-teal-darkest);
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-weight: 100 900;
  src: url('https://static.focus.teamleader.eu/fonts/Inter.var.woff2') format('woff2');
}

.heading,
.text,
.ui-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  font-family: var(--font-family-inter);
}

.text {
  font-weight: 400;
}

.text strong {
  font-weight: 600;
}

.ui-text {
  font-weight: 500;
}

.ui-text strong {
  font-weight: 700;
}

.heading-1 {
  font-size: calc(2.4 * var(--unit));
  font-weight: 700;
  line-height: calc(3 * var(--unit));
}

.heading-2 {
  font-size: calc(1.8 * var(--unit));
  font-weight: 500;
  line-height: calc(2.4 * var(--unit));
}

.heading-3 {
  font-size: calc(1.6 * var(--unit));
  font-weight: 500;
  line-height: calc(2.1 * var(--unit));
}

.heading-4 {
  font-size: calc(1.2 * var(--unit));
  font-weight: 700;
  line-height: calc(1.8 * var(--unit));
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.heading-5 {
  font-size: calc(1.4 * var(--unit));
  font-weight: 600;
  line-height: calc(1.8 * var(--unit));
}

.heading a {
  text-decoration: none;
}

.heading a:hover {
  text-decoration: underline;
}

.text-display {
  font-size: calc(1.6 * var(--unit));
  line-height: calc(2.4 * var(--unit));
}

.text-body {
  font-size: calc(1.4 * var(--unit));
  line-height: calc(2.1 * var(--unit));
}

.text-body-compact {
  font-size: calc(1.4 * var(--unit));
  line-height: calc(1.8 * var(--unit));
}

.text-small {
  font-size: calc(1.2 * var(--unit));
  line-height: calc(1.8 * var(--unit));
}

.ui-text-display {
  font-size: calc(1.6 * var(--unit));
  line-height: calc(2.4 * var(--unit));
}

.ui-text-body {
  font-size: calc(1.4 * var(--unit));
  line-height: calc(1.8 * var(--unit));
}

.ui-text-small {
  font-size: calc(1.2 * var(--unit));
  line-height: calc(1.8 * var(--unit));
}

/**
  Make sure all elements inherit the font-feature-settings
  Firefox seems to have a bug causing no inheritance at all
*/
.monospaced,
.monospaced * {
  font-feature-settings: 'tnum';
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
