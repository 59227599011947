/* stylelint-disable function-whitespace-after */
/* stylelint-disable function-no-unknown */
/* stylelint-disable custom-property-pattern */
.box {
  box-sizing: border-box;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

/* prettier-ignore */
@each
  $factor, $spacer in (1, 2, 3, 4, 5, 6, 7, 8), (var(--spacer-smallest), var(--spacer-smaller), var(--spacer-small), var(--spacer-regular), var(--spacer-medium), var(--spacer-big), var(--spacer-bigger), var(--spacer-biggest)) {
  .margin-bottom-$(factor) {
    margin-bottom: $spacer;
  }

  .margin-left-$(factor) {
    margin-left: $spacer;
  }

  .margin-right-$(factor) {
    margin-right: $spacer;
  }

  .margin-top-$(factor) {
    margin-top: $spacer;
  }

  .margin-bottom-negative-$(factor) {
    margin-bottom: calc($spacer * -1);
  }

  .margin-left-negative-$(factor) {
    margin-left: calc($spacer * -1);
  }

  .margin-right-negative-$(factor) {
    margin-right: calc($spacer * -1);
  }

  .margin-top-negative-$(factor) {
    margin-top: calc($spacer * -1);
  }

  .padding-bottom-$(factor) {
    padding-bottom: $spacer;
  }

  .padding-left-$(factor) {
    padding-left: $spacer;
  }

  .padding-right-$(factor) {
    padding-right: $spacer;
  }

  .padding-top-$(factor) {
    padding-top: $spacer;
  }
}

@each $display in (inline, inline-block, block, flex, inline-flex) {
  .display-$(display) {
    display: $display;
  }
}

@each $direction in (row, row-reverse, column, column-reverse) {
  .flex-direction-$(direction) {
    flex-direction: $direction;
  }
}

@each $wrap in (nowrap, wrap, wrap-reverse) {
  .flex-wrap-$(wrap) {
    flex-wrap: $wrap;
  }
}

@each $justify in (center, flex-start, flex-end, space-around, space-between, space-evenly) {
  .justify-content-$(justify) {
    justify-content: $justify;
  }
}

@each $content-alignment in (center, flex-start, flex-end, space-around, space-between, space-evenly) {
  .align-content-$(content-alignment) {
    align-content: $content-alignment;
  }
}

@each $item-alignment in (center, flex-start, flex-end, baseline, stretch) {
  .align-items-$(item-alignment) {
    align-items: $item-alignment;
  }
}

@each $self-alignment in (center, flex-start, flex-end, stretch) {
  .align-self-$(self-alignment) {
    align-self: $self-alignment;
  }
}

@each $color in (neutral, mint, violet, ruby, gold, aqua, teal) {
  .background-color-$(color) {
    background-color: var(--color-$(color));
  }

  @each $tint in (darkest, dark, light, lightest) {
    .background-color-$(color)-$(tint) {
      background-color: var(--color-$(color)-$(tint));
    }
  }
}

@each $text-alignment in (center, left, right) {
  .text-align-$(text-alignment) {
    text-align: $text-alignment;
  }
}
