:root {
  --color-aqua-lightest-hsl-h: 212;
  --color-aqua-lightest-hsl-s: 100%;
  --color-aqua-lightest-hsl-l: 97.45%;
  --color-aqua-lightest-hsl: var(--color-aqua-lightest-hsl-h) var(--color-aqua-lightest-hsl-s)
    var(--color-aqua-lightest-hsl-l);
  --color-aqua-lightest: hsl(var(--color-aqua-lightest-hsl));
  --color-aqua-light-hsl-h: 212;
  --color-aqua-light-hsl-s: 100%;
  --color-aqua-light-hsl-l: 90%;
  --color-aqua-light-hsl: var(--color-aqua-light-hsl-h) var(--color-aqua-light-hsl-s) var(--color-aqua-light-hsl-l);
  --color-aqua-light: hsl(var(--color-aqua-light-hsl));
  --color-aqua-hsl-h: 212;
  --color-aqua-hsl-s: 100%;
  --color-aqua-hsl-l: 80%;
  --color-aqua-hsl: var(--color-aqua-hsl-h) var(--color-aqua-hsl-s) var(--color-aqua-hsl-l);
  --color-aqua: hsl(var(--color-aqua-hsl));
  --color-aqua-dark-hsl-h: 212;
  --color-aqua-dark-hsl-s: 100%;
  --color-aqua-dark-hsl-l: 47.45%;
  --color-aqua-dark-hsl: var(--color-aqua-dark-hsl-h) var(--color-aqua-dark-hsl-s) var(--color-aqua-dark-hsl-l);
  --color-aqua-dark: hsl(var(--color-aqua-dark-hsl));
  --color-aqua-darkest-hsl-h: 212;
  --color-aqua-darkest-hsl-s: 100%;
  --color-aqua-darkest-hsl-l: 32.55%;
  --color-aqua-darkest-hsl: var(--color-aqua-darkest-hsl-h) var(--color-aqua-darkest-hsl-s)
    var(--color-aqua-darkest-hsl-l);
  --color-aqua-darkest: hsl(var(--color-aqua-darkest-hsl));
  --color-gold-lightest-hsl-h: 39;
  --color-gold-lightest-hsl-s: 100%;
  --color-gold-lightest-hsl-l: 94.9%;
  --color-gold-lightest-hsl: var(--color-gold-lightest-hsl-h) var(--color-gold-lightest-hsl-s)
    var(--color-gold-lightest-hsl-l);
  --color-gold-lightest: hsl(var(--color-gold-lightest-hsl));
  --color-gold-light-hsl-h: 40;
  --color-gold-light-hsl-s: 100%;
  --color-gold-light-hsl-l: 78.04%;
  --color-gold-light-hsl: var(--color-gold-light-hsl-h) var(--color-gold-light-hsl-s) var(--color-gold-light-hsl-l);
  --color-gold-light: hsl(var(--color-gold-light-hsl));
  --color-gold-hsl-h: 40;
  --color-gold-hsl-s: 100%;
  --color-gold-hsl-l: 70%;
  --color-gold-hsl: var(--color-gold-hsl-h) var(--color-gold-hsl-s) var(--color-gold-hsl-l);
  --color-gold: hsl(var(--color-gold-hsl));
  --color-gold-dark-hsl-h: 40;
  --color-gold-dark-hsl-s: 100%;
  --color-gold-dark-hsl-l: 46.47%;
  --color-gold-dark-hsl: var(--color-gold-dark-hsl-h) var(--color-gold-dark-hsl-s) var(--color-gold-dark-hsl-l);
  --color-gold-dark: hsl(var(--color-gold-dark-hsl));
  --color-gold-darkest-hsl-h: 25;
  --color-gold-darkest-hsl-s: 100%;
  --color-gold-darkest-hsl-l: 28.04%;
  --color-gold-darkest-hsl: var(--color-gold-darkest-hsl-h) var(--color-gold-darkest-hsl-s)
    var(--color-gold-darkest-hsl-l);
  --color-gold-darkest: hsl(var(--color-gold-darkest-hsl));
  --color-neutral-lightest-hsl-h: 0;
  --color-neutral-lightest-hsl-s: 0%;
  --color-neutral-lightest-hsl-l: 100%;
  --color-neutral-lightest-hsl: var(--color-neutral-lightest-hsl-h) var(--color-neutral-lightest-hsl-s)
    var(--color-neutral-lightest-hsl-l);
  --color-neutral-lightest: hsl(var(--color-neutral-lightest-hsl));
  --color-neutral-light-hsl-h: 240;
  --color-neutral-light-hsl-s: 23.08%;
  --color-neutral-light-hsl-l: 97.45%;
  --color-neutral-light-hsl: var(--color-neutral-light-hsl-h) var(--color-neutral-light-hsl-s)
    var(--color-neutral-light-hsl-l);
  --color-neutral-light: hsl(var(--color-neutral-light-hsl));
  --color-neutral-hsl-h: 240;
  --color-neutral-hsl-s: 3.85%;
  --color-neutral-hsl-l: 89.8%;
  --color-neutral-hsl: var(--color-neutral-hsl-h) var(--color-neutral-hsl-s) var(--color-neutral-hsl-l);
  --color-neutral: hsl(var(--color-neutral-hsl));
  --color-neutral-dark-hsl-h: 240;
  --color-neutral-dark-hsl-s: 3.28%;
  --color-neutral-dark-hsl-l: 76.08%;
  --color-neutral-dark-hsl: var(--color-neutral-dark-hsl-h) var(--color-neutral-dark-hsl-s)
    var(--color-neutral-dark-hsl-l);
  --color-neutral-dark: hsl(var(--color-neutral-dark-hsl));
  --color-neutral-darkest-hsl-h: 240;
  --color-neutral-darkest-hsl-s: 4.17%;
  --color-neutral-darkest-hsl-l: 52.94%;
  --color-neutral-darkest-hsl: var(--color-neutral-darkest-hsl-h) var(--color-neutral-darkest-hsl-s)
    var(--color-neutral-darkest-hsl-l);
  --color-neutral-darkest: hsl(var(--color-neutral-darkest-hsl));
  --color-mint-lightest-hsl-h: 180;
  --color-mint-lightest-hsl-s: 50%;
  --color-mint-lightest-hsl-l: 96.08%;
  --color-mint-lightest-hsl: var(--color-mint-lightest-hsl-h) var(--color-mint-lightest-hsl-s)
    var(--color-mint-lightest-hsl-l);
  --color-mint-lightest: hsl(var(--color-mint-lightest-hsl));
  --color-mint-light-hsl-h: 180;
  --color-mint-light-hsl-s: 58.49%;
  --color-mint-light-hsl-l: 58.43%;
  --color-mint-light-hsl: var(--color-mint-light-hsl-h) var(--color-mint-light-hsl-s) var(--color-mint-light-hsl-l);
  --color-mint-light: hsl(var(--color-mint-light-hsl));
  --color-mint-hsl-h: 180;
  --color-mint-hsl-s: 100%;
  --color-mint-hsl-l: 34.9%;
  --color-mint-hsl: var(--color-mint-hsl-h) var(--color-mint-hsl-s) var(--color-mint-hsl-l);
  --color-mint: hsl(var(--color-mint-hsl));
  --color-mint-dark-hsl-h: 181;
  --color-mint-dark-hsl-s: 100%;
  --color-mint-dark-hsl-l: 27.45%;
  --color-mint-dark-hsl: var(--color-mint-dark-hsl-h) var(--color-mint-dark-hsl-s) var(--color-mint-dark-hsl-l);
  --color-mint-dark: hsl(var(--color-mint-dark-hsl));
  --color-mint-darkest-hsl-h: 182;
  --color-mint-darkest-hsl-s: 100%;
  --color-mint-darkest-hsl-l: 15.1%;
  --color-mint-darkest-hsl: var(--color-mint-darkest-hsl-h) var(--color-mint-darkest-hsl-s)
    var(--color-mint-darkest-hsl-l);
  --color-mint-darkest: hsl(var(--color-mint-darkest-hsl));
  --color-ruby-lightest-hsl-h: 13;
  --color-ruby-lightest-hsl-s: 100%;
  --color-ruby-lightest-hsl-l: 96.27%;
  --color-ruby-lightest-hsl: var(--color-ruby-lightest-hsl-h) var(--color-ruby-lightest-hsl-s)
    var(--color-ruby-lightest-hsl-l);
  --color-ruby-lightest: hsl(var(--color-ruby-lightest-hsl));
  --color-ruby-light-hsl-h: 15;
  --color-ruby-light-hsl-s: 100%;
  --color-ruby-light-hsl-l: 82.55%;
  --color-ruby-light-hsl: var(--color-ruby-light-hsl-h) var(--color-ruby-light-hsl-s) var(--color-ruby-light-hsl-l);
  --color-ruby-light: hsl(var(--color-ruby-light-hsl));
  --color-ruby-hsl-h: 15;
  --color-ruby-hsl-s: 100%;
  --color-ruby-hsl-l: 62.55%;
  --color-ruby-hsl: var(--color-ruby-hsl-h) var(--color-ruby-hsl-s) var(--color-ruby-hsl-l);
  --color-ruby: hsl(var(--color-ruby-hsl));
  --color-ruby-dark-hsl-h: 15;
  --color-ruby-dark-hsl-s: 81.96%;
  --color-ruby-dark-hsl-l: 50%;
  --color-ruby-dark-hsl: var(--color-ruby-dark-hsl-h) var(--color-ruby-dark-hsl-s) var(--color-ruby-dark-hsl-l);
  --color-ruby-dark: hsl(var(--color-ruby-dark-hsl));
  --color-ruby-darkest-hsl-h: 15;
  --color-ruby-darkest-hsl-s: 100%;
  --color-ruby-darkest-hsl-l: 30%;
  --color-ruby-darkest-hsl: var(--color-ruby-darkest-hsl-h) var(--color-ruby-darkest-hsl-s)
    var(--color-ruby-darkest-hsl-l);
  --color-ruby-darkest: hsl(var(--color-ruby-darkest-hsl));
  --color-teal-lightest-hsl-h: 215;
  --color-teal-lightest-hsl-s: 66.67%;
  --color-teal-lightest-hsl-l: 96.47%;
  --color-teal-lightest-hsl: var(--color-teal-lightest-hsl-h) var(--color-teal-lightest-hsl-s)
    var(--color-teal-lightest-hsl-l);
  --color-teal-lightest: hsl(var(--color-teal-lightest-hsl));
  --color-teal-light-hsl-h: 213;
  --color-teal-light-hsl-s: 30.53%;
  --color-teal-light-hsl-l: 81.37%;
  --color-teal-light-hsl: var(--color-teal-light-hsl-h) var(--color-teal-light-hsl-s) var(--color-teal-light-hsl-l);
  --color-teal-light: hsl(var(--color-teal-light-hsl));
  --color-teal-hsl-h: 212;
  --color-teal-hsl-s: 17.7%;
  --color-teal-hsl-l: 47.65%;
  --color-teal-hsl: var(--color-teal-hsl-h) var(--color-teal-hsl-s) var(--color-teal-hsl-l);
  --color-teal: hsl(var(--color-teal-hsl));
  --color-teal-dark-hsl-h: 211;
  --color-teal-dark-hsl-s: 29.41%;
  --color-teal-dark-hsl-l: 23.33%;
  --color-teal-dark-hsl: var(--color-teal-dark-hsl-h) var(--color-teal-dark-hsl-s) var(--color-teal-dark-hsl-l);
  --color-teal-dark: hsl(var(--color-teal-dark-hsl));
  --color-teal-darkest-hsl-h: 220;
  --color-teal-darkest-hsl-s: 10.34%;
  --color-teal-darkest-hsl-l: 11.37%;
  --color-teal-darkest-hsl: var(--color-teal-darkest-hsl-h) var(--color-teal-darkest-hsl-s)
    var(--color-teal-darkest-hsl-l);
  --color-teal-darkest: hsl(var(--color-teal-darkest-hsl));
  --color-violet-lightest-hsl-h: 243;
  --color-violet-lightest-hsl-s: 100%;
  --color-violet-lightest-hsl-l: 95.49%;
  --color-violet-lightest-hsl: var(--color-violet-lightest-hsl-h) var(--color-violet-lightest-hsl-s)
    var(--color-violet-lightest-hsl-l);
  --color-violet-lightest: hsl(var(--color-violet-lightest-hsl));
  --color-violet-light-hsl-h: 243;
  --color-violet-light-hsl-s: 95.74%;
  --color-violet-light-hsl-l: 90.78%;
  --color-violet-light-hsl: var(--color-violet-light-hsl-h) var(--color-violet-light-hsl-s)
    var(--color-violet-light-hsl-l);
  --color-violet-light: hsl(var(--color-violet-light-hsl));
  --color-violet-hsl-h: 253;
  --color-violet-hsl-s: 100%;
  --color-violet-hsl-l: 56.08%;
  --color-violet-hsl: var(--color-violet-hsl-h) var(--color-violet-hsl-s) var(--color-violet-hsl-l);
  --color-violet: hsl(var(--color-violet-hsl));
  --color-violet-dark-hsl-h: 253;
  --color-violet-dark-hsl-s: 100%;
  --color-violet-dark-hsl-l: 36.08%;
  --color-violet-dark-hsl: var(--color-violet-dark-hsl-h) var(--color-violet-dark-hsl-s) var(--color-violet-dark-hsl-l);
  --color-violet-dark: hsl(var(--color-violet-dark-hsl));
  --color-violet-darkest-hsl-h: 253;
  --color-violet-darkest-hsl-s: 100%;
  --color-violet-darkest-hsl-l: 23.92%;
  --color-violet-darkest-hsl: var(--color-violet-darkest-hsl-h) var(--color-violet-darkest-hsl-s)
    var(--color-violet-darkest-hsl-l);
  --color-violet-darkest: hsl(var(--color-violet-darkest-hsl));
  --color-black-hsl-h: 0;
  --color-black-hsl-s: 0%;
  --color-black-hsl-l: 0%;
  --color-black-hsl: var(--color-black-hsl-h) var(--color-black-hsl-s) var(--color-black-hsl-l);
  --color-black: hsl(var(--color-black-hsl));
  --color-white-hsl-h: 0;
  --color-white-hsl-s: 0%;
  --color-white-hsl-l: 100%;
  --color-white-hsl: var(--color-white-hsl-h) var(--color-white-hsl-s) var(--color-white-hsl-l);
  --color-white: hsl(var(--color-white-hsl));
  --color-marketing-violet-lightest-hsl-h: 243;
  --color-marketing-violet-lightest-hsl-s: 100%;
  --color-marketing-violet-lightest-hsl-l: 95.49%;
  --color-marketing-violet-lightest-hsl: var(--color-marketing-violet-lightest-hsl-h)
    var(--color-marketing-violet-lightest-hsl-s) var(--color-marketing-violet-lightest-hsl-l);
  --color-marketing-violet-lightest: hsl(var(--color-marketing-violet-lightest-hsl));
  --color-marketing-violet-light-hsl-h: 243;
  --color-marketing-violet-light-hsl-s: 95.74%;
  --color-marketing-violet-light-hsl-l: 90.78%;
  --color-marketing-violet-light-hsl: var(--color-marketing-violet-light-hsl-h)
    var(--color-marketing-violet-light-hsl-s) var(--color-marketing-violet-light-hsl-l);
  --color-marketing-violet-light: hsl(var(--color-marketing-violet-light-hsl));
  --color-marketing-violet-hsl-h: 253;
  --color-marketing-violet-hsl-s: 100%;
  --color-marketing-violet-hsl-l: 56.08%;
  --color-marketing-violet-hsl: var(--color-marketing-violet-hsl-h) var(--color-marketing-violet-hsl-s)
    var(--color-marketing-violet-hsl-l);
  --color-marketing-violet: hsl(var(--color-marketing-violet-hsl));
  --color-marketing-violet-dark-hsl-h: 253;
  --color-marketing-violet-dark-hsl-s: 100%;
  --color-marketing-violet-dark-hsl-l: 36.08%;
  --color-marketing-violet-dark-hsl: var(--color-marketing-violet-dark-hsl-h) var(--color-marketing-violet-dark-hsl-s)
    var(--color-marketing-violet-dark-hsl-l);
  --color-marketing-violet-dark: hsl(var(--color-marketing-violet-dark-hsl));
}
