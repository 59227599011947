/* stylelint-disable keyframes-name-pattern */
:root {
  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);
}

@keyframes :global(pulse) {
  0% {
    opacity: 0.25;
  }

  25% {
    opacity: 0.25;
  }

  35% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  75% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes :global(spin) {
  to {
    transform: rotate(360deg);
  }
}

@keyframes :global(fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes :global(fadeOut) {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
