@import url('../../libs/ahoy/src/index.css');

:global {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    height: 100vh;
    overflow: hidden;
  }

  #react-root {
    height: 100%;
  }
}

.container {
  height: 100%;
}

.main-image {
  position: absolute;
  bottom: 0;
  height: 120%;
}

.main-image-container {
  height: 380px;
  width: 100%;
  position: relative;
}
