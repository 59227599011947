.link {
  cursor: pointer;
  background: transparent;
  border: 0;
  font-size: 100%;
  padding: 0;

  svg {
    transform: translate3d(0, -1px, 0);
  }

  * + svg,
  svg + * {
    margin-left: var(--spacer-smaller);
  }

  &.is-disabled {
    opacity: 0.48;
    pointer-events: none;
  }

  &:focus-visible {
    outline: 2px solid var(--color-aqua-dark);
  }

  &:active {
    outline: 0;
  }

  &:not(.is-inherit) {
    color: var(--color-aqua-dark);
    font-family: var(--font-family-inter);
    font-weight: 400;

    &:not(.is-inverse) {
      text-decoration: none;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    &.is-inverse {
      text-decoration: underline;

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  &.is-inherit {
    color: inherit;

    &:hover,
    &:active {
      color: inherit;
    }

    &:not(.is-inverse) {
      text-decoration: underline;

      &:hover,
      &:active {
        text-decoration: none;
      }
    }

    &.is-inverse {
      text-decoration: none;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
}
